import React, { Component } from 'react';
import { Tab, Label, Table, Container, Input } from 'semantic-ui-react';
import './App.css';
import axios from 'axios';

const url = 'https://523jwhpmcc.execute-api.us-east-1.amazonaws.com/dev/closings/'

class Labels extends Component {

    /**
     * Take a string and convert it from
     * "title case" -> "Title Case"
     * @param {String} text 
     */
    toTitleCase(text) {
        return text
                .toLowerCase().split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
    }

    /**
     * Render method for labels. This looks for keywords
     * in the closing label and changes the color based
     * on them.
     * 
     * Because -> Blue
     * Closed || Cancelled || No || Closings -> Red
     * Late || Delayed -> Yellow
     * Open || Normal -> Green
     */
    render() {
        const { data } = this.props;

        return data.split(',').map(label => {
            label = label.toLowerCase();
            console.log(label);
            console.log(typeof lable);
            if(label.includes('because'))
                return (<Label key={label} color='blue'>{this.toTitleCase(label)}</Label>)

            if(label.includes('closed') || label.includes('cancelled') || label.includes('no') || label.includes('closing'))
                return (<Label key={label} color='red'>{this.toTitleCase(label)}</Label>)
    
            if(label.includes('late') || label.includes('delayed') || label.includes('begins') || label.includes('ends') || label.includes('early'))
                return (<Label key={label} color='orange'>{this.toTitleCase(label)}</Label>)
    
            if(label.includes('open') || label.includes('normal') || label.includes('employees'))
                return (<Label key={label} color='green'>{this.toTitleCase(label)}</Label>)

            if(label && label != "undefined" && label != "Undefined" && label != undefined) {
                return (<Label key={label} color='grey'>{this.toTitleCase(label)}</Label>)
            }

            return <React.Fragment></React.Fragment>
        })
    }
}

class Closing extends Component {
    toTitleCase(text) {
        return text.toLowerCase().split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
    }

    render() {
        const { data } = this.props;
        return data.map(closing => {
            let closingStatus = "";

            if(closing && closing.Status1 && closing.Status1[0])
                closingStatus += closing.Status1.join(',')
            if(closing && closing.Status2 && closing.Status2[0])
                closingStatus += "," + closing.Status2.join(',')

            return (
                <Table.Row key={closing.ID[0]}>
                    <Table.Cell>
                        <p>{this.toTitleCase(closing.Name1[0])}</p>
                    </Table.Cell>
                    <Table.Cell>
                        <p>{this.toTitleCase(closing.City[0])}</p>
                    </Table.Cell>
                    <Table.Cell>
                        <Labels data={this.toTitleCase(closingStatus)} />
                    </Table.Cell>
                </Table.Row>
            )
        })
    }
}

class ClosingTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            closings: null,
            last_updated: new Date(),
            interval: null
        }
    }

    async loadClosings() {
        try {
            const response = await axios.get(`https://media.psg.nexstardigital.net/WGNR/closings/closings.json?timestamp=${new Date().getTime()}`);
            const closingsTemp = response.data.Closing;
            let closings = [];
            if(closingsTemp != undefined) {
                closingsTemp.map(c => {
                    console.log(c.EntityType[0].indexOf(this.props.type));
                    if(c.EntityType[0].indexOf(this.props.type) > -1) {
                        closings.push(c);
                    }
                })
            }
            this.setState({closings, filtered: closings, last_updated: response.data['$'].Time})
        } catch(error) {
            console.log(error);
        }
    }

    async getData(type) {
        try {
            const response = await axios.get(url + type);
            const closings = response.data.data;

            this.setState({closings, filtered: closings, last_updated: new Date()});
        } catch(error) {
            console.log(error);
        }
    }

    filter(e, { value }) {
        let closings = this.state.closings;

        this.setState({
            filtered: closings.filter(closing => (closing.Name1[0].toLowerCase().includes(value.toLowerCase()) || closing.City[0].toLowerCase().includes(value.toLowerCase()) ))
        })
    }

    componentDidMount() {
        this.loadClosings();
        const interval = setInterval(() => { this.loadClosings() }, 120000)
        this.setState({interval})
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    render() {
        const { filtered, last_updated, closings } = this.state;

        if(!filtered) {
            return <div>Loading...</div>
        }

        return (
            <div style={{border: '1px solid #d4d4d5', borderTop: 'none', padding: 10, height: 800, overflow: 'auto' }}>
                <Input icon='search' placeholder='Search...' onChange={this.filter.bind(this)} />
                <span style={{marginLeft: 10}}>Last Updated:</span>
                <span style={{fontWeight: 'bold', marginLeft: 5}}>{last_updated}</span>
                {(closings.length <= 0) &&
                    <div style={{fontWeight: 'bold', marginTop: 25, fontSize: 24}}>No closings to report.</div>
                }
                {(filtered.length > 0) &&
                    <Table celled basic='very'>
                        <Table.Body>{<Closing data={filtered} />}</Table.Body>
                    </Table>
                }
            </div>
        )
    }
}

class PublicSchool extends Component {
    render() {
        return <ClosingTab type='Public School' />
    }
}

class PrivateSchool extends Component {
    render() {
        return <ClosingTab type='Private School' />
    }
}

class College extends Component {
    render() {
        return <ClosingTab type='College' />
    }
}

class Daycare extends Component {
    render() {
        return <ClosingTab type='Day Care' />
    }
}

class Business extends Component {
    render() {
        return <ClosingTab type='Business' />
    }
}

class Religous extends Component {
    render() {
        return <ClosingTab type='Religious' />
    }
}

class Government extends Component {
    render() {
        return <ClosingTab type='Government' />
    }
}

class App extends Component {

    constructor(props) {
        super(props)

        this.state = {
            school_count: 0,
            business_count: 0,
            church_count: 0,
            misc_count: 0,
            closings: null
        }
    }

    async loadClosings() {
        try {
            const response = await axios.get(`https://media.psg.nexstardigital.net/WGNR/closings/closings.json`);
            return response.data;
        } catch(error) {
            console.log(error);
        }
    }

    async getData(type) {
        try {
            const response = await axios.get(url + type);
            return response.data.data;
        } catch(error) {
            console.log(error);
        }
    }

    componentDidMount() {
        //this.loadClosings().then(v => this.setState({'closings': v.Closing}));
        /*this.getData('School').then(v => this.setState({'school_count': v.length}))
        this.getData('Business').then(v => this.setState({'business_count': v.length}))
        this.getData('Church').then(v => this.setState({'church_count': v.length}))
        this.getData('Misc').then(v => this.setState({'misc_count': v.length}))*/
    }

    render() {
        const {closings} = this.state;

        const panes = [
            { menuItem: `Public School`, render: () => <PublicSchool /> },
            { menuItem: `Private School`, render: () => <PrivateSchool /> },
            { menuItem: `College`, render: () => <College /> },
            { menuItem: `Daycare`, render: () => <Daycare /> },
            { menuItem: `Religious`, render: () => <Religous />},
            { menuItem: `Government`, render: () => <Government />},
            { menuItem: `Business`, render: () => <Business />}
        ]

        return (
            <Tab style={{height: 800}} panes={panes} renderActiveOnly={true} />
        );
    }
}

export default App;
